exports.components = {
  "component---src-pages-12-dprinter-js": () => import("./../../../src/pages/12dprinter.js" /* webpackChunkName: "component---src-pages-12-dprinter-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adventofcode-js": () => import("./../../../src/pages/adventofcode.js" /* webpackChunkName: "component---src-pages-adventofcode-js" */),
  "component---src-pages-autoformatter-js": () => import("./../../../src/pages/autoformatter.js" /* webpackChunkName: "component---src-pages-autoformatter-js" */),
  "component---src-pages-chillenium-js": () => import("./../../../src/pages/chillenium.js" /* webpackChunkName: "component---src-pages-chillenium-js" */),
  "component---src-pages-comppro-js": () => import("./../../../src/pages/comppro.js" /* webpackChunkName: "component---src-pages-comppro-js" */),
  "component---src-pages-cs-462-js": () => import("./../../../src/pages/cs462.js" /* webpackChunkName: "component---src-pages-cs-462-js" */),
  "component---src-pages-current-js": () => import("./../../../src/pages/current.js" /* webpackChunkName: "component---src-pages-current-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-facebook-js": () => import("./../../../src/pages/facebook.js" /* webpackChunkName: "component---src-pages-facebook-js" */),
  "component---src-pages-frc-js": () => import("./../../../src/pages/frc.js" /* webpackChunkName: "component---src-pages-frc-js" */),
  "component---src-pages-gradescope-js": () => import("./../../../src/pages/gradescope.js" /* webpackChunkName: "component---src-pages-gradescope-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kidkraft-js": () => import("./../../../src/pages/kidkraft.js" /* webpackChunkName: "component---src-pages-kidkraft-js" */),
  "component---src-pages-marblesorter-js": () => import("./../../../src/pages/marblesorter.js" /* webpackChunkName: "component---src-pages-marblesorter-js" */),
  "component---src-pages-mergesort-js": () => import("./../../../src/pages/mergesort.js" /* webpackChunkName: "component---src-pages-mergesort-js" */),
  "component---src-pages-polypack-js": () => import("./../../../src/pages/polypack.js" /* webpackChunkName: "component---src-pages-polypack-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-reu-js": () => import("./../../../src/pages/reu.js" /* webpackChunkName: "component---src-pages-reu-js" */),
  "component---src-pages-scara-js": () => import("./../../../src/pages/scara.js" /* webpackChunkName: "component---src-pages-scara-js" */),
  "component---src-pages-scara-webapp-js": () => import("./../../../src/pages/scara_webapp.js" /* webpackChunkName: "component---src-pages-scara-webapp-js" */),
  "component---src-pages-skills-js": () => import("./../../../src/pages/skills.js" /* webpackChunkName: "component---src-pages-skills-js" */),
  "component---src-pages-solidworks-js": () => import("./../../../src/pages/solidworks.js" /* webpackChunkName: "component---src-pages-solidworks-js" */),
  "component---src-pages-spark-js": () => import("./../../../src/pages/spark.js" /* webpackChunkName: "component---src-pages-spark-js" */),
  "component---src-pages-sumobots-js": () => import("./../../../src/pages/sumobots.js" /* webpackChunkName: "component---src-pages-sumobots-js" */),
  "component---src-pages-swirl-js": () => import("./../../../src/pages/swirl.js" /* webpackChunkName: "component---src-pages-swirl-js" */),
  "component---src-pages-taassignment-js": () => import("./../../../src/pages/taassignment.js" /* webpackChunkName: "component---src-pages-taassignment-js" */),
  "component---src-pages-template-js": () => import("./../../../src/pages/template.js" /* webpackChunkName: "component---src-pages-template-js" */),
  "component---src-pages-tinysns-js": () => import("./../../../src/pages/tinysns.js" /* webpackChunkName: "component---src-pages-tinysns-js" */),
  "component---src-pages-urs-js": () => import("./../../../src/pages/urs.js" /* webpackChunkName: "component---src-pages-urs-js" */),
  "component---src-pages-vexu-js": () => import("./../../../src/pages/vexu.js" /* webpackChunkName: "component---src-pages-vexu-js" */),
  "component---src-pages-website-js": () => import("./../../../src/pages/website.js" /* webpackChunkName: "component---src-pages-website-js" */),
  "component---src-pages-wikipedia-js": () => import("./../../../src/pages/wikipedia.js" /* webpackChunkName: "component---src-pages-wikipedia-js" */)
}

